.exercise-description-parsed {
  padding: 20px;

  .code code {
    background-color: var(--code-background-color);
    border-radius: 5px;
    padding: 5px;
  }

  .markdown {
    table {
      border-collapse: collapse;
      margin-bottom: 20px;
      background-color: var(--code-background-color);
      border-radius: 0.3em;
      width: 100%;
    }

    th,
    td {
      padding: 0.5rem 1rem;
      border: 3px solid var(--border-color);
    }
  }

  img {
    width: 100%;
  }

  a {
    color: var(--secondary-color);
  }

  & > *:first-child,
  & > *:first-child * {
    margin-top: 0;
  }

  h1,
  h2 {
    font-weight: 700;
    margin: 2rem 0 1rem 0;
  }

  h3,
  h4,
  h5,
  h6 {
    margin: 1rem 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 2rem 0 1rem 0;
  }

  p {
    margin-bottom: 1rem;
  }

  &-titles {
    &-subtitle {
      font-size: 1rem !important;
      font-weight: bold !important;
      text-transform: uppercase;
      color: var(--primary-color);
      margin: 0 !important;
    }
  }

  // pre {
  //   display: flex;
  //   margin: 0.5em 0 !important;
  // }
}
