.tabMenu {
  display: flex;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px 5px 0 0;

  .tabMenuItem {
    padding: 10px 1rem;
    cursor: pointer;
    border-bottom: 3px solid transparent;
    color: #fff;

    &:hover {
      color: var(--accent-color);
    }

    &.activeTab {
      border-bottom-color: var(--accent-color); // Change as needed
      color: var(--accent-color) !important;
      font-weight: bold;
    }
  }
}