.apply-promo-code {
  .MuiButtonBase-root {
    height: 40px;
  }
}

.shopping-cart-item {
  &-simple {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .shopping-cart-left {
      display: flex;
      flex-direction: column;
      img {
        width: 100%;
        height: auto;
        border-radius: 12px;
        margin-bottom: 12px;
      }

      p {
        margin: 0;
        font-size: 21px;
      }
    }

    .course-price-wrapper {
      .course-price {
        font-size: 1rem;
        font-weight: 300;
      }
    }
  }
}
