.image-picker-wrapper {
  position: relative;
  height: 100%;
}

.upload-profile-avatar:hover,
.upload-profile-image-hover:hover {
  ~ .upload-profile-image-hover {
    display: flex;
  }
}

.upload-profile-image-hover {
  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  top: 0;
  cursor: pointer;

  &:hover {
    display: flex;
  }
}
