.top-menu-dropdown-layout {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 100%;
  height: 100%;

  .title {
    display: flex;
    justify-content: center;
    padding: 16px;
    font-size: 20px;
    font-weight: 600;
    min-width: 220px;
    background-color: var(--secondary-color);
  }

  .content {
    background-color: var(--surface-color);
    flex: 1;
  }
}