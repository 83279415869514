.container-with-side-menu {
  display: flex;
  height: 100vh;

  .container-main {
    overflow: scroll;
    width: 100%;

    .full-width-layout-container {
      flex: 1;
      margin-top: var(--top-menu-height);
    }
  }

  .container-right {
    margin-top: var(--top-menu-height);
    position: relative;
    display: flex;
    max-width: 600px;
    flex-shrink: 0;
    align-items: flex-start;
    border-left: var(--border);
    overflow-y: scroll;
    overflow-x: hidden;
  }
}