.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent backdrop
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000; // Ensure it's above other content
  color: var(--text-color);
  border: 0;

  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    border-bottom: 1px solid var(--border-color);
  }

  .modalFooter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;
    border-top: 1px solid var(--border-color);
  }

  .modalBox {
    background-color: var(--surface-color);
    border-radius: 4px;
    box-shadow: 0px 11px 1rem -7px rgba(0, 0, 0, 0.2),
      0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    overflow: hidden; // Adjust as per design
    position: relative;
    // Responsive width styles
    width: 90vw; // Default width
    max-width: 600px; // Adjust as needed
    max-height: 80vh;

    @media (min-width: 600px) {
      width: unset;
    }

    .modalContent {
      height: 100%;
      overflow: auto;
      padding: 1.6rem;
      position: relative;
    }
  }

  &.fullWidth {
    .modalBox {
      max-width: 100%;
      width: 80vw;

      max-height: 100%;
      height: 80vh;
    }
  }
}