.rating-highlight-container {
  align-items: center;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 50px;
    margin: 0;
    text-align: center;
  }

  .rating-review-text {
    min-width: 0;
    font-size: 0.75rem;
    line-height: 1.5;
    margin-top: 0;
    text-align: center;
    display: inline;
    white-space: nowrap;
  }
}

.rating-item-container {
  height: 100%;
}

.rating-list-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rating-item-profile {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .rating-profile-image {
    border-radius: 100%;
    overflow: hidden;
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .rating-profile-stars {
    display: flex;
    align-items: center;
  }
}
