// Drawer.module.scss
.drawerBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--background-color);
    display: flex;
    align-items: center;
    justify-content: flex-end; // Aligns the drawer to the right
    z-index: 1000; // Ensure it's above other content
    visibility: hidden; // Initially hidden
    opacity: 0;
    transition: visibility 0s, opacity 0.3s ease;

    &.open {
        visibility: visible;
        opacity: 1;
    }
}

.drawerContent {
    width: 100%;
    height: 100%;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
    transform: translateX(100%); // Initially off-screen

    &.open {
        transform: translateX(0); // Slide in
    }
    overflow: hidden;


    .drawerBody {
        height: calc(100vh - 82px);
        overflow: hidden auto;
        // padding-bottom: 2rem;
    }
}