.container {
    position: relative;
    display: inline-block;
    width: 100%;

    .select {
        width: 100%;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        font-weight: 400;
        color: var(--text-color);
        background-color: var(--background-color);
        border: var(--border);
        border-radius: 0.25rem;
        appearance: none;
        cursor: pointer;
        transition: border-color 0.15s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:focus {
            outline: none;
            border-color: var(--primary-color);
        }
    }

    .selectMenu {
        background-color: var(--background-color);
        position: absolute;
        z-index: 1000;
        width: 100%;
        overflow: auto;

        .option {
            padding: 0.5rem 1rem;
            font-size: 1rem;
            font-weight: 400;
            color: var(--text-color);
            background-color: var(--background-color);
            border: var(--border);
            cursor: pointer;
            transition: background-color 0.15s ease-in-out;

            &:hover {
                background-color: var(--surface-color);
            }

            &:active {
                background-color: var(--secondary-color);
            }
        }
    }
}