.main-footer {
  background-color: var(--surface-color);
  padding: 2rem 0;

  p {
    text-align: left;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      margin-bottom: 0.5rem;
    }
  }

  a {
    color: #fff;

    &:hover {
      color: var(--secondary-color);
    }
  }
}
