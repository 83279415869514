.container {
    display: flex;
    align-items: center;
    justify-content: center;

    .stepContainer {
        display: inline-grid;
        grid-auto-flow: column;
        overflow: hidden;
        overflow-x: auto;
        counter-reset: step;
        grid-auto-columns: 1fr;

        .step {
            display: grid;
            grid-template-columns: repeat(1, minmax(0, 1fr));
            grid-template-columns: auto;
            grid-template-rows: repeat(2, minmax(0, 1fr));
            place-items: center;
            text-align: center;

            &:after {
                content: counter(step);
                counter-increment: step;
                z-index: 1;
                position: relative;
                grid-column-start: 1;
                grid-row-start: 1;
                display: grid;
                height: 2.5rem;
                width: 2.5rem;
                place-items: center;
                place-self: center;
                border-radius: 9999px;
                background-color: var(--background-color);
                color: var(--text-color);
            }

            &:not(:first-child):before {
                top: 0;
                grid-column-start: 1;
                grid-row-start: 1;
                height: .5rem;
                width: 100%;
                transform: translate(0, 0) rotate(0) skew(0) skewY(0) scaleX(1) scaleY(1);
                content: "";
                margin-inline-start: -100%;
                background-color: var(--background-color);
                color: var(--background-color);
                content: "";
            }

            &.active:after,
            &.active:before {
                background-color: var(--primary-color);
                color: var(--background-color);
            }

            &.errored:after {
                content: "!";
                background-color: var(--red);
                color: var(--text-color);
            }
        }
    }
}