.sidebar-item-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid var(--border-color);

    &.noBorder {
        border-bottom: none;
    }

    .sidebar-item {
        border-left: 4px solid transparent;
        width: 100%;
        padding: 1rem;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        color: var(--text-color);

        &.isActive,
        &:hover {
            border-left-color: var(--accent-color);
            background-color: var(--primary-color);
        }

        &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }

        .sidebar-wrapper {
            position: relative;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            width: 100%;

            .sidebar-icon {
                width: 1.5rem;
                height: 1.5rem;
            }
        }
    }
}