.progressbarIcon {
    position: relative;
}

.progressBackground {
    fill: none;
    stroke: var(--background-color);
}

.progressForeground {
    fill: none;
    stroke: var(--primary-color);
    stroke-linecap: round;
    transition: stroke-dashoffset 0.3s;
    transform: rotate(-90deg) translate(-44px, 0);

    &.loading {
        transform: unset;
        animation: rotate 2s linear infinite;
        transform-origin: center center;
        transform-box: fill-box;
    }
}

@keyframes rotate {
    100% {
        transform: rotate(1turn);
    }
}
