.featured-image-wrapper {
  width: 175px;
  height: 175px;
}

.header-container {
  :global(.exercise-description-parsed) {
    margin: 0;
    padding: 0;

    :global(p:last-child) {
      margin-bottom: 0;
    }
  }
}
