.table {
    background-color: var(--surface-color);
    border: var(--border);
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;

    .head {
        background-color: var(--background-color);

        .headerRow {
            background-color: var(--surface-color);

            .headerRowCell {}
        }
    }

    .body {
        .bodyRow {
            background-color: var(--background-color);

            &:nth-child(even) {
                background-color: var(--surface-color);
            }

            &:hover {
                background-color: var(--border-color);

                &.clickable {
                    cursor: pointer;
                    background-color: var(--secondary-color);
                }
            }

            .bodyCell {
                border-top: var(--border);
            }
        }
    }

    .headerRowCell,
    .bodyCell {
        padding: 10px;
        color: var(--text-color);
        flex: 1;

        a {
            color: var(--text-color);
        }

        border-right: var(--border);

        &:last-child {
            border-right: none;
        }
    }
}