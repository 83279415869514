.menu-container {
  margin: 0;

  .items {
    display: flex;
    flex-direction: column;
    white-space: pre;

    &:not(:last-child) {
      border-right: var(--border);
    }

    .item {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      text-wrap: wrap;
      overflow: hidden;
      border-radius: .5rem;

      a {
        flex: 1;
        padding: .5rem;
      }

      a:link,
      a:visited,
      a:active {
        text-decoration: none;
        color: #ffffff;
      }

      a:hover {
        background-color: var(--primary-color);
      }
    }
  }
}