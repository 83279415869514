// Checkbox.module.scss
.checkboxContainer {
    display: inline-block;
    vertical-align: middle;


    .checkboxInput {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    .checkboxLabel {
        position: relative;
        cursor: pointer;
        // margin-left: 0.5rem;
        padding-left: 2rem;
        user-select: none;
        display: flex;
        align-items: center;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 16px;
            height: 16px;
            border: 2px solid rgba(255, 255, 255, 0.4);
            border-radius: 2px;
            background-color: transparent;
        }

        &:after {
            content: '';
            position: absolute;
            left: 6px;
            top: -2px;
            width: 5px;
            height: 9px;
            border: solid var(--background-color);
            border-width: 0 3px 3px 0;
            transform: translateY(-50%) rotate(45deg);
            opacity: 0;
        }
    }


    &.withLabel {
        .checkboxLabel:after {
            top: 11px;
        }
    }


    .checkboxInput:checked+.checkboxLabel:before {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
    }

    .checkboxInput:checked+.checkboxLabel:after {
        opacity: 1;
    }

    .checkboxInput:disabled+.checkboxLabel {
        color: red;
        cursor: not-allowed;
    }
}