.code-block-wrapper {
  position: relative;
  width: fit-content !important;
  max-width: 100%;
  border-radius: 5px;
  border: 1px solid var(--code-background-color);
  overflow: hidden;
  margin: 1rem 0;

  .copy-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px !important;
    min-width: unset;
  }

  .run-code-button {
    display: flex;
    justify-content: flex-end;
    padding: 10px 10px 10px 0 !important;
    background-color: var(--background-color) !important;
  }

  .code-block-content {
    background-color: var(--code-background-color) !important;
    // copy button is 30px wide and 10px padding on both sides, that is why we have 50px padding on the right
    padding: 10px 50px 10px 1rem !important;

    :global([data-bright-theme]) {
      width: 100%;
      margin: 0 !important;

      div {
        background-color: var(--background-color) !important;
      }

      pre {
        margin: 0 !important;
        padding: 0 !important;
        background-color: transparent !important;
        white-space: pre-wrap !important;
        /* Since CSS 2.1 */
        white-space: -moz-pre-wrap !important;
        /* Mozilla, since 1999 */
        white-space: -pre-wrap !important;
        /* Opera 4-6 */
        white-space: -o-pre-wrap !important;
        /* Opera 7 */
        word-wrap: break-word !important;
        /* Internet Explorer 5.5+ */

        div {
          background-color: transparent !important;
          padding: 0 !important;
          margin: 0 !important;
        }
      }

      [data-bright-tab] {
        background-color: var(--code-background-color) !important;
      }
    }
  }
}


.highlighted-code code {
  background-color: var(--primary-color);
  border-radius: 0.3em;

  padding: 2px 4px;
  margin-bottom: 8px;
  display: inline-flex;

  margin-top: -2px;
}