.top-menu-container {
  position: relative;

  .top-menu-toggle-button {
    background: none;
    cursor: pointer;
    box-shadow: none;
    color: var(--text-color);
    padding: 1rem;
    border: none;
  }
}