.upload-dropzone {
  label {
    margin: auto;

    border-color: var(--primary-color);
    height: 100px;
    span {
      color: #fff !important;
    }
  }
}
