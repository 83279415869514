.resources-menu-container {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}
.resources-menu-items {
  display: flex;
  flex-direction: column;
  white-space: pre;
  gap: 20px;
  a {
    flex: 1;
  }
  a:link,
  a:visited,
  a:active {
    text-decoration: none;
    color: #ffffff;
  }

  a:hover {
    color: var(--secondary-color);
  }
}
