.course-price-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-evenly;

  &-flex {
    flex-direction: column;
    justify-content: center;
    h1 {
      margin-bottom: 0px;
    }
  }

  .course-price {
    margin: 0;

    &-monthly {
      font-size: 1rem;
      font-weight: 400;
    }
  }

  .course-previous-price {
    margin: 0 10px;
    font-weight: 400;
    color: graytext;

    &-old {
      text-decoration: line-through;
    }
  }

  .course-price-discount {
    margin-left: 10px;
    color: graytext;
  }

  &.course-price-wrapper-payment {
    align-items: flex-end;
    margin: 0;

    h1 {
      font-size: 1.5rem;
    }

    .course-previous-price {
      font-size: 0.8rem;
    }
  }

  &.course-price-wrapper-course-list {
    justify-content: flex-start;

    h1 {
      font-size: 2rem;
    }

    .course-previous-price {
      display: flex;
    }
  }
}
