.button {
  /* Add your button styles here */
  display: inline-flex;
  align-items: center;
  justify-content: center;

  :global(.startIcon) {
    svg {
      /* Add your svg styles here */
      display: flex;

      margin-right: 8px;
      margin-left: -4px;
    }
  }

  :global(.endIcon) {
    svg {
      /* Add your svg styles here */
      display: flex;

      margin-left: 8px;
      margin-right: -4px;
    }
  }

  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  background-color: var(--primary-color);
  color: #fff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
  0px 2px 2px 0px rgba(0, 0, 0, 0.14),
  0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  text-transform: unset;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover,
  &.active {
    background-color: var(--primary-color-dark);
    box-shadow:
      0px 3px 3px -2px rgba(0, 0, 0, 0.2),
      0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  }

  &.outlined {
    /* Add your outlined variant styles here */
    background-color: transparent !important;
    border: 1px solid var(--primary-color-dark);
    color: var(--primary-color);

    &:hover,
    &.active {
      border-color: var(--primary-color);
    }

    &.secondary {
      border-color: var(--secondary-color-dark);
      color: var(--secondary-color);

      &:hover,
      &.active {
        border-color: var(--secondary-color);
      }
    }

    &.accent {
      border-color: var(--accent-color);
      color: var(--accent-color);

      &:hover,
      &.active {
        border-color: var(--accent-color);
      }
    }
  }

  &.text {
    /* Add your text variant styles here */
    background-color: transparent !important;
    font-weight: 900;
    box-shadow: none;

    &:hover,
    &.active {
      color: var(--secondary-color);
    }


    &.accent {
      &:hover,
      &.active {
        color: var(--accent-color);
      }
    }
  }

  &.cta {
    /* Add your primary color styles here */
    background-color: var(--cta-color);
    color: var(--surface-color);

    margin-bottom: 10px !important;
    padding: 10px 16px !important;
    box-shadow: -4px 4px #ffffff;
    font-weight: 900;

    &:hover,
    &.active {
      background-color: var(--cta-color);
      color: var(--surface-color);
      box-shadow: -6px 6px #ffffff;
    }
  }

  &.secondary {
    /* Add your secondary color styles here */
    background-color: var(--secondary-color);

    &:hover,
    &.active {
      background-color: var(--secondary-color-dark);
    }
  }

  &.accent {
    /* Add your accent color styles here */
    background-color: var(--accent-color);

    &:hover,
    &.active {
      background-color: var(--accent-color);
    }
  }

  .loader {
    /* Add your loading styles here */
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 16px;
      height: 16px;
      margin: 2px;
      border: 2px solid #fff;
      border-radius: 50%;
      animation: loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #fff transparent transparent transparent;
    }

    div:nth-child(1) {
      animation-delay: -0.45s;
    }

    div:nth-child(2) {
      animation-delay: -0.3s;
    }

    div:nth-child(3) {
      animation-delay: -0.15s;
    }

    @keyframes loading {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }

  &.fullWidth {
    /* Add your full width styles here */
    width: 100%;
  }

  &.disabled {
    /* Add your disabled styles here */
    cursor: not-allowed;
    background-color: var(--primary-color-dark);
    color: rgba(255, 255, 255, 0.4);

    &.outlined {
      background-color: transparent;
    }

    &.secondary {
      background-color: var(--secondary-color-dark);
    }

    &.accent {
      background-color: var(--accent-color);
    }

    &:hover,
    &.active {
      color: unset;
    }

    &.text {
      background-color: transparent;
    }
  }
}