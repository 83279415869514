.container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 16px;
  flex: 0.5;
}

.top-icon {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  position: absolute;
  top: -36px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-container {
  height: 175px;
  padding: 30px 30px 0 30px;
  background-color: var(--surface-color);
  border: 1px solid var(--code-background-color);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.above-child {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.below-child {
  flex: 1;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  border-top: var(--border);
  // align-items: flex-start;
}

.middle-container-card {
  @media screen and (min-width: 960px) {
    margin-top: -50px;
  }
}
