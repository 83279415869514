@keyframes animation-eyp2zy {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 100% 100%;
    }
}

.badge {
    padding: 0.125rem 0.5rem;
    border-radius: 0.125rem;
    font-weight: bold;
    text-align: center;

    &.animate {
        animation-duration: 3s;
        animation-delay: 0s;
        animation-direction: normal;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        animation-play-state: running;
        animation-iteration-count: infinite;
        animation-name: animation-eyp2zy;
        background-size: 1rem 1rem;
        background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 0%, transparent 50%, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 75%, transparent 0%, transparent 100%);
    }

    &.small {
        font-size: 1rem;
        padding: 0 0.25rem;
    }
}