.accordion {
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 1rem;
    transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    padding: 16px;
    background-color: var(--surface-color);
}

.summary {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .expandIcon {
        transition: transform 0.3s ease;
    }

    &.expanded .expandIcon {
        transform: rotate(180deg);
    }
}

.details {
    display: none;
    margin-top: 1rem;

    &.expanded {
        display: block;
    }
}