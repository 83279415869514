.top-menu-container {
  z-index: 1000;
  position: fixed;
  top: 0;
  width: 100%;
  background: var(--gradient-background);

  .top-menu-container-inner {
    background-color: rgba(0, 0, 0, 0.39);

    .top-menu-container-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: var(--top-menu-height);

      .top-menu-left-side {
        display: flex;
        align-items: center;
        height: 100%;

        img {
          // max-width: 150px;
        }
      }
    }

    .leave-rating-modal {
      background-color: var(--background-color);
      border: var(--border);
      padding: 20px 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .leave-rating-modal-close {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;
      }

      .rating-description {
        background-color: transparent;
        width: 100%;
        margin: 20px 0;
        padding: 10px;
        color: var(--text-color);
      }
    }
  }

  .left-side-actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 650px) {
    .left-side-actions {
      display: none;
    }

    .top-menu-left-side {
      justify-content: space-between;
      flex: 1;
    }
  }
}

.top-menu-item {
  color: #fff !important;
  margin-left: 20px !important;

  &:hover {
    opacity: 0.7;
  }
}