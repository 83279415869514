.container {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background-color: var(--primary-color);
    border: none;
    cursor: pointer;

    &:hover {
        background-color: var(--secondary-color);
    }

    font-size: 1rem;
    color: var(--text-color);
}

.active {
    background-color: var(--secondary-color);
}