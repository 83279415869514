.editorContainer {
    padding: 20px;
    margin: 20px auto;
    background-color: var(--background-color);
    border-radius: 5px;
    color: var(--text-color);

    .headerItem {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        position: relative;

        .headerLabel {
            width: 100%;
            font-size: 20px;
            font-weight: bold;
            margin-right: 1rem;
            display: flex;
            align-items: center;

            .headerLabelText {
                background: var(--surface-color);
                border-radius: 2px;
                padding: 2px 6px;
                margin-right: 8px;
                font-size: 1rem;
                text-transform: uppercase;
            }


            .inputField {
                flex-grow: 1;
                border-radius: 4px;
                padding: 10px;
            }
        }

        .actionButton {
            cursor: pointer;
            background: none;
            border: none;
            margin-left: 10px;
        }


        .addButton {
            padding: 5px 10px;
            margin-left: 1rem;
            border: none;
            border-radius: 4px;
            cursor: pointer;
        }
    }
}