.container {
  display: flex;
  gap: .5rem;
  height: 100%;
  padding: 1rem !important;
}

.image-container {
  display: flex;
  align-items: flex-start;
}

.title-body-container {
  display: flex;
  flex-direction: column;

}