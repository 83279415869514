.preview-banner-wrapper {
  display: flex;
  width: 100%;
  cursor: pointer;
  position: relative;

  &-radius {
    .course-preview-side-card-main-image {
      border-radius: 10px;
    }

    .course-featured-image-play-button {
      border-radius: 10px;
    }
  }

  :not(.noFullWidth) {
    &.course-preview-side-card-main-image {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }

  .course-featured-image-play-button {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(28, 29, 31, 0) 0%, rgba(28, 29, 31, 0.9) 100%);

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-weight: bold;

      .course-featured-image-play-button-icon {
        width: 5rem;
        height: 5rem;
      }

      p {
        position: absolute;
        bottom: 1rem;
      }
    }
  }
}