.pointerPath {
    fill: #5a5a5a;
}

.pointerCircle {
    fill: #5a5a5a;
}

.default {
    fill: var(--background-color);
}

.green {
    fill: var(--green);
}

.orange {
    fill: #ff9900;
}

.red {
    fill: var(--red);
}

.yellow {
    fill: var(--yellow);
}

.mainValue {
    font-size: 24.9454px;
    fill: rgb(255, 255, 255);
    text-shadow:
        black 1px 0.5px 0px,
        black 0px 0px 0.03em,
        black 0px 0px 0.01em;
    text-anchor: middle;
}

.small-text {
    font-size: 23px;
    fill: #ffffff;
    color: #ffffff;
    text-anchor: middle;
}