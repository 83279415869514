.breadcrumbWrapper {
    display: inline-flex;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 10px;
    margin: 1.125rem 0 !important;

    ol {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            display: flex;
        }
    }

    .crumb {
        color: #fff;

        &.crumb-link {
            font-weight: bold;

            &:hover {
                color: var(--secondary-color);
            }
        }

        &.crumb-disabled {
            color: rgba(255, 255, 255, 0.85);
        }
    }
}