.tabs-wrapper {
  width: 100%;
  height: 100%;

  .tabs-container {
    display: flex;
    // gap: 0.5rem;
  }

  &:not(.no-background) {
    .tabs-container {
      background-color: var(--background-color);
    }
  }

  .content {
    // margin-top: 1rem;
    height: 100%;
  }
}