.this-platform-container {
  .this-platform-wrapper {
    display: flex;
    @media screen and (max-width: 960px) {
      flex-direction: column;
    }

    flex: 1;
    gap: 40px;

    @media screen and (max-width: 960px) {
      gap: 0;
    }

    .this-platform-text-container {
      overflow-wrap: anywhere;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }
  .hands-on-image {
    width: inherit;
    border-radius: 10px;

    @media screen and (max-width: 960px) {
      width: 100%;
      height: 100%;
      margin-left: 0px;
    }
  }

  .highlight-label {
    font-weight: bold;
    border-radius: 2px;
    display: inline-block;
    background-color: var(--yellow);
    color: var(--background-color);
    padding: 2px 7px;
    margin-right: 10px;
  }
}
