.side-menu-container {
  container-type: inline-size;
  container-name: side-menu;
  position: relative;

  @container side-menu (width < 250px) {
    div {
      font-size: 0.75rem;

      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }

  z-index: 10000;
  background: var(--gradient-background);
  margin-top: var(--top-menu-height);
  display: flex;
  flex-direction: column;
  max-width: 300px;
  transition: max-width 0.3s ease-in-out;
  width: 100%;

  &-collapsed {
    max-width: 100px;
  }

  .inner {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    overflow: auto;

    .collapsible {
      position: absolute;
      right: -20px;
      bottom: 30px;
      cursor: pointer;
      z-index: 10000;
    }

    .items-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      background-color: rgba(0, 0, 0, 0.39);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.39);
      justify-content: space-between;
    }
  }
}