.course-main-information {
}

.logo {
  background-color: var(--code-background-color);
  padding: 5px;
  border-radius: 50%;
  margin-right: 10px;
}

.time-to-read {
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  padding: 1px 5px;
  background-color: #fff;
  color: #000;
}

.section {
  color: rgba(255, 255, 255, 0.85);
}
