// DropdownMenu.module.scss
.dropdownMenu {
    position: fixed;
    display: flex;
    flex-direction: column;
    max-width: 800px;
    transition: opacity 273ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 182ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transform-origin: 0px 0px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
    outline: 0px;
    overflow: hidden auto;
    background-color: var(--surface-color);
    z-index: 1000;


    &.hidden {
        display: none;
    }

    .menuList {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .menuItem {
        padding: 10px 1rem;
        cursor: pointer;

        &:hover {
            background-color: #f5f5f5;
        }
    }
}