.seo-dropdowns {
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  flex-direction: column;

  .success {
    background-color: var(--green);
  }

  .warning {
    background-color: var(--yellow);
  }

  .error {
    background-color: var(--red);
  }
}