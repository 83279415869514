.settings-card {
  padding: 24px;
}

.about-you-stack {
  display: flex;
  margin-bottom: 18px;
}

.about-you-stack-right-column {
  display: flex;
  flex-direction: column;
}

.button-container {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
