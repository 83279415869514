.seo-preview {  
  word-break: break-word;
  font-family: Arial, sans-serif;
  background-color: var(--surface-color);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    font-size: 14px;
    font-weight: 600;
  }

  .url {
    a {
      font-size: 14px;
      line-height: 16px;
      margin: 3px 0 5px;
      padding-right: 5px;
      color: var(--secondary-color) !important;
    }
  }

  .title {
    color: var(--primary-color);
    font-size: 20px;
    line-height: 1.3;
    margin-top: 10px;
  }

  .description {
    color: var(--gray);
    font-size: 14px;
    margin-top: 5px;
  }
}