$form-color: var(--border-color);
$font-size: 16px;
$label-transition-duration: 0.2s;
$label-scale-factor: 0.8; // Label will shrink to 80% of its size
$text-color: #ebebeb;

.inputContainer {
    margin: 1rem 0 0.5rem 0;

    .formControl {
        position: relative;
        width: 100%;

        .input {
            width: 100%;
            padding: 1rem;
            border: 1px solid $form-color;
            border-radius: 4px;
            font-size: $font-size;
            color: $text-color;
            background-color: transparent;
            transition:
                border-color $label-transition-duration,
                box-shadow $label-transition-duration;

            &:focus {
                outline: none;
                border-color: var(--primary-color);
                box-shadow: 0 0 0 3px rgba(var(--primary-color), 0.2);
            }
        }

        .label {
            position: absolute;
            top: 50%;
            left: 1rem;
            transform: translateY(-50%);
            padding: 0 0.25rem;
            background-color: transparent;
            color: #ebebeb;
            transition: all $label-transition-duration;
            pointer-events: none;
            transform-origin: left center;

            &.focused,
            &.filed {
                transform: translateY(-160%) scale($label-scale-factor);

                &.multiline {
                    transform: translateY(-350%) scale($label-scale-factor);
                }

                background-color: var(--surface-color);
                border-radius: 5px;
            }

            &.focused {
                color: #fff;
            }
        }

        .errorInput {
            border-color: var(--red);
        }
    }

    .helperText {
        font-size: 0.8 * $font-size;
        color: var(--red);
        margin-top: 0.5rem;
    }
}
