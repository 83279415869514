.item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: var(--background-color);
  border-top: 5px solid transparent;

  &:hover {
    border-top: 5px solid var(--secondary-color);
  }

  cursor: pointer;
}

.active-item {
  background-color: var(--surface-color);
  border-top: 5px solid var(--secondary-color);
  font-weight: bold;
}