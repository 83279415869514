.cell {
  padding: 1rem;

  &:first-child {
    border-left: var(--border) !important;
  }

  &:not(:last-child) {
    border-right: var(--border) !important;
  }

  &:last-child {
    border-right: var(--border) !important;
  }

  &.primary {
    background-color: var(--primary-color)
  }
}