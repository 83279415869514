.page-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &-content {
    text-align: center;

    img {
      width: 100%;
    }

    p {
      font-size: 3rem;
      margin: 0;
      color: var(--secondary-color);
    }
  }
}
