.dropdown {
  width: 100%;
  &-title {
    background-color: var(--surface-color);
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  &-absolute {
    position: relative;
    border: 0 !important;

    .dropdown-content {
      position: absolute;
      width: 100%;
      background-color: var(--surface-color);
      border: 3px solid var(--background-color);

      &-item {
        padding: 10px;
      }
    }
  }

  &-content {
    display: inherit;

    &-hidden {
      display: none;
    }
  }
}
