.card-wrapper {
  display: flex;
  height: 100%;
  border-radius: 4px;
  border: 1px solid var(--code-background-color);

  position: relative;
  transition:
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;

  transform: translate(0, -0);
  box-shadow: -0.25rem 0.25rem transparent !important;

  &:hover {
    box-shadow: -0.5rem 0.5rem var(--secondary-color) !important;
    transform: translate(0.25rem, -0.25rem);
  }
}
