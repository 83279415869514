$top-menu-margin: 2rem;

$toolbar-height: 60px;
$toolbar-offset: calc(var(--top-menu-height) + $top-menu-margin + 10px);

$border-radius: 5px;

$max-editor-width-no-sidebar: calc(100vw - var(--dashboard-right-sidebar-width) - 5.5rem);

// 100px is the width of the left small sidebar
$max-editor-width-with-sidebar: calc($max-editor-width-no-sidebar - 100px);

$statusbar-height: 40px;
$statusbar-bottom: 2rem;

.editor {
    width: 100%;

    .editorWrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 100%;
        min-height: calc(100vh - $toolbar-offset - 90px);
        background-color: var(--surface-color);

        .loading {
            margin: auto;
            height: 100%;
        }
    }

    .stickyToolbalMock {
        position: sticky;
        top: var(--top-menu-height);
        background-color: var(--background-color);
        z-index: 1;
        margin-top: -$top-menu-margin;
        height: calc($top-menu-margin + $toolbar-height);
        border-bottom: var(--border);

        .stickyToolbalMockInner {
            position: sticky;
            top: $toolbar-offset;
            background-color: var(--surface-color);
            height: $toolbar-height;
            border-radius: $border-radius $border-radius 0 0;
        }
    }

    .stickyStatusbarMock {
        position: sticky;
        bottom: 0;
        background-color: var(--background-color);
        z-index: 1;
        height: $statusbar-height;

        .stickyStatusbarMockInner {
            position: sticky;
            bottom: $statusbar-bottom;
            // background-color: var(--surface-color);
            height: $statusbar-height;
            border-radius: $border-radius $border-radius 0 0;
        }
    }

    :global(.tox-statusbar) {
        position: fixed !important;
        z-index: 10;
        max-width: $max-editor-width-with-sidebar;
        width: 100%;
        height: $statusbar-height !important;
        bottom: 2rem;
        background-color: var(--surface-color) !important;
        border-radius: 0 0 $border-radius $border-radius !important;
        border-top: var(--border) !important;

        :global(.tox-statusbar__path-item),
        :global(.tox-statusbar__wordcount) {
            color: var(--text-color) !important;
        }
    }

    &--preview {
        :global(.tox-statusbar) {
            max-width: $max-editor-width-no-sidebar;
        }
    }

    :global(.tox.tox-tinymce) {
        width: 100%;
        border: none !important;
        border-radius: 0 0 $border-radius $border-radius;

        :global(.tox-editor-container) {
            background-color: var(--surface-color) !important;
            display: flex;
            flex: 1;
            margin-top: 40px;

            :global(.tox-editor-header) {
                background-color: transparent;

                :global(.tox-toolbar-overlord) {
                    width: inherit;
                    position: fixed;
                    max-width: $max-editor-width-with-sidebar;
                    top: $toolbar-offset;
                    background-color: var(--surface-color) !important;

                    :global(.tox-toolbar__primary) {
                        background-color: transparent !important;

                        :global(.tox-tbtn) {
                            background-color: transparent;

                            :global(.tox-tbtn__select-label) {
                                color: var(--text-color);
                            }

                            svg {
                                fill: var(--text-color);
                            }
                        }
                    }
                }
            }

            :global(.tox-edit-area) {
                display: flex;
                align-items: center;
                justify-content: center;

                :global(iframe) {
                    background-color: #201e2f !important;
                    outline: none;

                    width: 100%;
                    max-width: 1100px;
                }
            }
        }
    }
}